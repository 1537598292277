import React from "react";
import Cashiers from "../Components/Cashier";
// import { Button } from "@mui/material";

function Cashier() {
  return (
    <Cashiers/>
  )
}

export default Cashier;
