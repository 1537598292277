import { Button } from "@mui/material";
import React from "react";
function CashMenuModal({ cashMenuModal, setCashMenuModal }) {
  return (
    <div
      className="modal__cashmenu"
      onClick={(e) => {
        if (!cashMenuModal) {
          setCashMenuModal(true);
        } else if (cashMenuModal) {
          setCashMenuModal(false);
        }
      }}
    >
      <div className="top">
        <h3>Jon Doe</h3>
        <p>cashier</p>
      </div>

      <div className="main">
        <Button
          className="btn"
          style={{
            color: "#000000",
            width: "100%",
            borderRadius: "0px",
          }}
        >
          <i className="bx bx-user"></i>My profile
        </Button>
        <Button
          className="btn"
          style={{
            color: "#000000",
            width: "100%",
            borderRadius: "0px",
          }}
        >
          <i className="bx bx-cog"></i>Settings
        </Button>
      </div>
      <div className="bottom">
        <Button
          className="btn"
          style={{
            color: "#000000",
            width: "100%",
            borderRadius: "0px ",
          }}
        >
          <i className="bx bx-log-out-circle"></i>My profile
        </Button>
      </div>
    </div>
  );
}

export default CashMenuModal;
