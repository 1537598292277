import { Button } from "@mui/material";
import React, { useState } from "react";
import checkImg from "../Assests/img/check.svg";

function CashPaymentModal({ cashPaymentModall, setPashPaymentModall }) {
  const [payment, setPayment] = useState(false);

  if (payment && cashPaymentModall) {
    setTimeout(() => {
      setPayment(false);
      setPashPaymentModall(false);
    }, 2000);
  }

  return (
    <div className="modal__cashpayment">
      {/* <div className="container " onClick={() => setPashPaymentModall(false)}> */}
      <div className="container ">
        <div className="check">
          <img src={checkImg} alt="" width="400" height="794" />
        </div>

        <div className="left">
          <div className="top">
            <div>
              <p>К оплате</p>
              <h3 className="red">100 000</h3>
            </div>

            <div>
              <p>Сумма чека</p>
              <h3>189 000</h3>
            </div>

            <div>
              <p>Сдача</p>
              <h3 className="green">11 000</h3>
            </div>

            <div>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#8BC34A",
                  border: "1px solid #6B9837",
                  borderRadius: "5px",
                  padding: "9px 17px",
                  fontWeight: "300",
                  fontSize: "20px",
                  lineHeight: "150%",
                  color: "#FFFFFF",
                }}
                onClick={(e) => setPayment(true)}
              >
                Payment <i className="bx bx-chevron-right"></i>
              </Button>
            </div>
          </div>

          <div className="hero">
            <div className="cards">
              <div className="card">
                <div>
                  <p>Naqt</p>
                  <Button
                    style={{
                      color: "red",
                      fontSize: "24px",
                      padding: "0",
                      borderRadius: "50%",
                      minWidth: "26px",
                      maxWidth: "26px",
                    }}
                  >
                    <i className="bx bx-x"></i>
                  </Button>
                </div>
                <input type="number" placeholder="189 000" />
              </div>

              <div className="card">
                <div>
                  <p>Naqt</p>
                  <Button
                    style={{
                      color: "red",
                      fontSize: "24px",
                      padding: "0",
                      borderRadius: "50%",
                      minWidth: "26px",
                      maxWidth: "26px",
                    }}
                  >
                    <i className="bx bx-x"></i>
                  </Button>
                </div>
                <input type="number" placeholder="189 000" />
              </div>

              <div className="card">
                <div>
                  <p>Naqt</p>
                  <Button
                    style={{
                      color: "red",
                      fontSize: "24px",
                      padding: "0",
                      borderRadius: "50%",
                      minWidth: "26px",
                      maxWidth: "26px",
                    }}
                  >
                    <i className="bx bx-x"></i>
                  </Button>
                </div>
                <input type="number" placeholder="189 000" />
              </div>
            </div>

            <div className="buttons">
              <Button className="btn">naqd</Button>
              <Button className="btn">uzcard</Button>
              <Button className="btn">humo</Button>
              <Button disabled className="btn disabled">
                payme
              </Button>
            </div>
          </div>
        </div>
      </div>

      {payment ? (
        <div
          className="modal__done"
          onClick={(e) => {
            setPayment(false);
            setPashPaymentModall(false);
          }}
        >
          <div>
            <i className="bx bx-check-circle"></i>
            <p>Done )</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default CashPaymentModal;
