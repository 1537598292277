import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import accImg from "../Assests/img/acc.png";
import itemImg from "../Assests/img/Vector.svg";
import imgFunkFoiz from "../Assests/img/funk-foiz.svg";
import imgFunkUsa from "../Assests/img/funk-doll.svg";
import imgFunkH from "../Assests/img/funk-h.svg";
import imgFunkKar from "../Assests/img/funk-kar.svg";
import imgFunkList from "../Assests/img/funk-list.svg";
import imgFunkLoad from "../Assests/img/funk-loader.svg";
import CashMenuModal from "./CashMenuModal";
import CashPaymentModal from "./CashPaymentModal";
// import buyList from "../Malumot/malumot";
// import
// import { Button } from "@mui/material";

function Cashiers() {
  let [cashMenuModal, setCashMenuModal] = useState(false);
  const [cashPaymentModall, setPashPaymentModall] = useState(false);

  // const [alignment, setAlignment] = React.useState(null);

  // const handleAlignment = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  //   console.log(alignment);
  // };

  // let countChanger = (e, el, index, qw) => {
  //   console.log();
  // };

  return (
    <section className="cashier">
      <div className="container cashier__container">
        <div className="cashier__main">
          <div className="cashier__main-header">
            <Button
              style={{
                boxSizing: "border-box",
                backgroundColor: "#ffffff",
                color: "#000000",
                maxWidth: "56px",
                minWidth: "56px",
                height: "56px",
                borderRadius: "8px",
              }}
              className="cashier__main-header-"
            >
              <i className="bx bx-menu bx-md"></i>
            </Button>
            <label htmlFor="search">
              <i className="bx bx-search"></i>
            </label>

            <input
              id="search"
              type="text"
              placeholder="Enter the barcode, article, name or category of the product"
            />
          </div>

          {/* Cashier buy list */}
          <div className="cashier__main-list">
            <div className="cashier__main-item">
              <div className="title">
                <div className="img">
                  <img src={itemImg} alt="" width="28" height="28" />
                </div>
                <div>
                  <h4>Яблоки голден местные</h4>
                  <p>#1000002132312</p>
                </div>
              </div>
              <div className="price">
                <p>1 257 985.18 UZS</p>
                {/* <p>1 257 985.18 UZS</p> */}
              </div>

              <div className="counter">
                <Button
                  className="minus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginRight: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-minus"></i>
                </Button>
                <span>1</span>
                <Button
                  className="pilus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-plus"></i>
                </Button>

                <Button
                  className="trash"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#FF0000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "30px",
                    border: "2px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            </div>

            <div className="cashier__main-item">
              <div className="title">
                <div className="img">
                  <img src={itemImg} alt="" width="28" height="28" />
                </div>
                <div>
                  <h4>Яблоки голден местные</h4>
                  <p>#1000002132312</p>
                </div>
              </div>
              <div className="price">
                <p>1 257 985.18 UZS</p>
                {/* <p>1 257 985.18 UZS</p> */}
              </div>

              <div className="counter">
                <Button
                  className="minus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginRight: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-minus"></i>
                </Button>
                <span>1</span>
                <Button
                  className="pilus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-plus"></i>
                </Button>

                <Button
                  className="trash"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#FF0000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "30px",
                    border: "2px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            </div>

            <div className="cashier__main-item">
              <div className="title">
                <div className="img">
                  <img src={itemImg} alt="" width="28" height="28" />
                </div>
                <div>
                  <h4>Яблоки голден местные</h4>
                  <p>#1000002132312</p>
                </div>
              </div>
              <div className="price">
                <p>1 257 985.18 UZS</p>
                {/* <p>1 257 985.18 UZS</p> */}
              </div>

              <div className="counter">
                <Button
                  className="minus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginRight: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-minus"></i>
                </Button>
                <span>1</span>
                <Button
                  className="pilus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-plus"></i>
                </Button>

                <Button
                  className="trash"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#FF0000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "30px",
                    border: "2px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            </div>

            <div className="cashier__main-item">
              <div className="title">
                <div className="img">
                  <img src={itemImg} alt="" width="28" height="28" />
                </div>
                <div>
                  <h4>Яблоки голден местные</h4>
                  <p>#1000002132312</p>
                </div>
              </div>
              <div className="price">
                <p>1 257 985.18 UZS</p>
                {/* <p>1 257 985.18 UZS</p> */}
              </div>

              <div className="counter">
                <Button
                  className="minus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginRight: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-minus"></i>
                </Button>
                <span>1</span>
                <Button
                  className="pilus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-plus"></i>
                </Button>

                <Button
                  className="trash"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#FF0000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "30px",
                    border: "2px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            </div>

            <div className="cashier__main-item">
              <div className="title">
                <div className="img">
                  <img src={itemImg} alt="" width="28" height="28" />
                </div>
                <div>
                  <h4>Яблоки голден местные</h4>
                  <p>#1000002132312</p>
                </div>
              </div>
              <div className="price">
                <p>1 257 985.18 UZS</p>
                {/* <p>1 257 985.18 UZS</p> */}
              </div>

              <div className="counter">
                <Button
                  className="minus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginRight: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-minus"></i>
                </Button>
                <span>1</span>
                <Button
                  className="pilus"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "18px",
                    border: "1px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-plus"></i>
                </Button>

                <Button
                  className="trash"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#FF0000",
                    fontSize: "24px",
                    maxWidth: "48px",
                    minWidth: "48px",
                    height: "48px",
                    borderRadius: "8px",
                    marginLeft: "30px",
                    border: "2px solid #EAEAEA",
                  }}
                >
                  <i className="bx bx-trash"></i>
                </Button>
              </div>
            </div>
          </div>

          <div className="cashier__main-bottom">
            <div className="left">
              <div className="jami">
                <p>Jami summa</p>
                <h3>87 076 400</h3>
              </div>

              <div className="chegirma">
                <p>Chegirma</p>
                <h3>76 400</h3>
              </div>
            </div>

            <div className="tolov">
              <p>To’lov summasi</p>
              <h3>87 000 000</h3>
            </div>
          </div>
        </div>

        {/* Cashier left */}
        <div className="cashier__left">
          <div className="cashier__left-header">
            <div className="left">
              <p className="kassa">Kassa #13</p>
              <p>September 19, 2022 05:48</p>
            </div>

            <button
              className="acc"
              onClick={(e) => {
                if (!cashMenuModal) {
                  setCashMenuModal(true);
                } else if (cashMenuModal) {
                  setCashMenuModal(false);
                }
              }}
            >
              <p>John doe</p>
              <img src={accImg} alt="" width="32p" height="32" />
            </button>
          </div>

          <div className="cashier__left-main">
            <div className="header">
              <Button
                style={{
                  height: "54px",
                  background: "#0099FF",
                  color: "#ffffff",
                  borderRadius: "8px",
                }}
              >
                <img src={imgFunkFoiz} alt="" />
              </Button>
              <Button
                style={{
                  height: "54px",
                  background: "#121D2B",
                  borderRadius: "8px",
                  color: "#ffffff",
                }}
              >
                <img src={imgFunkUsa} alt="" />
              </Button>
              <Button
                style={{
                  height: "54px",
                  background: "#E28A06",
                  borderRadius: "8px",
                  color: "#ffffff",
                }}
              >
                <img src={imgFunkH} alt="" />
              </Button>
              <Button
                style={{
                  height: "54px",
                  background: "#FF0000",
                  borderRadius: "8px",
                  color: "#ffffff",
                }}
              >
                <img src={imgFunkKar} alt="" />
              </Button>
            </div>

            <div className="list">
              <div className="box">
                <Button className="item">
                  <p>item</p>
                </Button>

                <Button className="item">
                  <p>item</p>
                </Button>
                <Button className="item">
                  <p>item</p>
                </Button>

                <Button className="item">
                  <p>item</p>
                </Button>
                <Button className="item">
                  <p>item</p>
                </Button>

                <Button className="item">
                  <p>item</p>
                </Button>
                <Button className="item">
                  <p>Item</p>
                </Button>
                <Button className="item">
                  <p>item</p>
                </Button>

                {/* <Button className="item">
                  <p>item</p>
                </Button>
                <Button className="item">
                  <p>item</p>
                </Button> */}
              </div>
            </div>

            <div className="buttom">
              <Button
                style={{
                  color: "#FFFFFF",
                  background: "#121D2B",
                  borderRadius: "8px",
                  height: "56px",
                  minWidth: "60px",
                }}
              >
                <img src={imgFunkList} alt="" width="32" />
              </Button>
              <Button
                style={{
                  color: "#FFFFFF",
                  background: "#0099FF",
                  borderRadius: "8px",
                  height: "56px",
                  minWidth: "60px",
                }}
              >
                <img src={imgFunkLoad} alt="" width="32" />
              </Button>
              <Button
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "21px",
                  color: "#FFFFFF",
                  background: "#0ABD11",
                  borderRadius: "8px",
                  height: "56px",
                  minWidth: "60px",
                  flexGrow: "1",
                }}
                onClick={(e) => {
                  if (!cashPaymentModall) {
                    setPashPaymentModall(true);
                  } else if (cashPaymentModall) {
                    setPashPaymentModall(false);
                  }
                }}
              >
                To’lash
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* MODALL */}

      {cashMenuModal ? (
        <CashMenuModal
          cashMenuModal={cashMenuModal}
          setCashMenuModal={setCashMenuModal}
        />
      ) : (
        <div></div>
      )}

      {cashPaymentModall ? (
        <CashPaymentModal
          cashPaymentModall={cashPaymentModall}
          setPashPaymentModall={setPashPaymentModall}
        />
      ) : (
        <div></div>
      )}
    </section>
  );
}

export default Cashiers;
