import React from "react";

function NotFound() {
  return (
    <div className="NotFound mx-auto w-11/12">
      <h1>Page NotFound 1 )</h1>
    </div>
  );
}

export default NotFound;
