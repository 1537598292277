// import React, { useEffect, useState } from "react";
// const Alert = () => {
//   return (
//     <div className="alert alert--success">
//       <span className="alert__txt">
//         Model muvaffaqiyatli qo’shildi. Qo’shimcha ma’lumot kerak bo’lsa
//         yoziladi{" "}
//       </span>
//       <button className="alert__btn" type="button"></button>
//     </div>
//   );
// };
// export default Alert;

export const AlertWarning = (props) => {
  return (
    <>
      {props.startAlert ? (
        <div
          className={
            props.danger === "bad"
              ? "alert alert--warning alert-top-right"
              : props.danger === "good"
              ? "alert alert--success alert-top-right"
              : "alert__none"
          }
        >
          <i className="bx bx-info-circle"></i>
          <span className="alert__txt">{props.message}</span>
          <button className="alert__btn" onClick={props.close} type="button">
            <i className="bx bxs-x-circle"></i>
          </button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

// export const AlertWarningTop = (props) => {
//   const [start, setStart] = useState(props.start);
//   useEffect(() => {
//     setStart(props.start);
//   }, [props.start, props.danger, props.message, props.handleSubmit]);

//   return (
//     <>
//       {start ? (
//         <div
//           className={
//             props.danger == "bad"
//               ? "alert alert--warning alert-top-right"
//               : props.danger == "good"
//               ? "alert alert--success alert-top-right"
//               : "alert__none"
//           }
//         >
//           <span className="alert__txt">{props.message}</span>
//           <button
//             className="alert__btn"
//             onClick={() => {
//               setStart(false);
//             }}
//             type="button"
//           ></button>
//         </div>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };
