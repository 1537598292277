import Button from "@mui/material/Button";
// import axios from "axios";
// import { TextField } from "@mui/material";
// import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../Hooks/useToken";
import { AlertWarning } from "./Alert";

const Login = () => {
  const [passwordCheck, setPasswordCheck] = useState(true);
  const [alertStyle] = useState("bad");
  const [startAlert, setStartAlert] = useState(false);

  const [responseNumber, setResponseNum] = useState();
  const [errorDetails, setErrorDetails] = useState("Error");

  useEffect(
    (e) => {
      setTimeout((e) => {
        setStartAlert(false);
      }, 6000);
      // setAlertStyle("none");
    },
    [startAlert]
  );

  const navigate = useNavigate();
  let [token, setToken] = useToken();
  const [userName, setUserEmail] = useState({
    value: "",
    status: false,
  });
  const [password, setPassword] = useState({
    value: "",
    status: false,
  });

  const Post = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer {{token}}");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: userName.value, ///  heroku-admin
      password: password.value, /// 123
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://e-inpos.herokuapp.com/api/login/", requestOptions)
      .then((response) => {
        setResponseNum(response.status);
        return response.json();
      })
      .then((result) => {
        if (result.access && result.refresh) {
          console.log(result, "salom", responseNumber);
          setToken(result.access);
          if (token !== "null" && token) {
            navigate("/cashier");
          }
          return;
        } else if (result.detail) {
          setErrorDetails(result.detail);
          setStartAlert(true);
          console.log(result, responseNumber);
        } else if (result.userName) {
          setErrorDetails(result.userName);
          setStartAlert(true);
          console.log(result, responseNumber);
        }
      })
      .catch((error) => console.log("error", error));

    // setToken(userName.value);
    // if (token !== "null" && token) {
    //   navigate("/cashier");
    // }
  };
  const userEmailChangeHandler = (e) => {
    let update = { ...userName };
    update.value = e.target.value;
    update.status = false;
    setUserEmail(update);
  };
  const PasswordChangeHandler = (e) => {
    let update = { ...password };
    update.value = e.target.value;
    update.status = false;
    setPassword(update);
  };

  return (
    <div className="login">
      <AlertWarning
        danger={alertStyle}
        startAlert={startAlert}
        message={errorDetails}
        close={() => {
          setStartAlert(false);
        }}
      />
      <div className="login__left">
        <form
          action=""
          onSubmit={(e) => {
            Post(e);
          }}
        >
          <h2 className="login__title">Log in</h2>
          <p>
            <span>Enter your details to login.</span>
            For your safety, do not share your information with anyone
          </p>
          <div className="box">
            <label htmlFor="login-name">
              <i className="bx bx-user"></i>
            </label>
            <input
              id="login-name"
              type="text"
              placeholder="Enter username"
              minLength="3"
              required
              onChange={(e) => {
                userEmailChangeHandler(e);
              }}
            />
            {passwordCheck ? (
              <div>
                <label id="password" htmlFor="login-password">
                  <i className="bx bx-lock"></i>
                </label>
                <input
                  id="login-password"
                  type="password"
                  placeholder="Enter password"
                  value={password.value}
                  minLength="3"
                  required
                  onChange={(e) => {
                    PasswordChangeHandler(e);
                  }}
                />
                <p
                  className="btn-eye"
                  type="buttun"
                  onClick={(e) => {
                    setPasswordCheck(false);
                  }}
                >
                  <i className="bx bx-low-vision"></i>
                </p>
              </div>
            ) : (
              <div>
                <label id="password" htmlFor="login-password">
                  <i className="bx bx-lock"></i>
                </label>
                <input
                  id="login-password"
                  type="text"
                  placeholder="Enter password"
                  value={password.value}
                  required
                  onChange={(e) => {
                    PasswordChangeHandler(e);
                  }}
                />
                <p
                  className="btn-eye"
                  type="buttun"
                  onClick={(e) => {
                    setPasswordCheck(true);
                  }}
                >
                  <i className="bx bx-low-vision"></i>
                </p>
              </div>
            )}
          </div>

          <Button
            style={{
              backgroundColor: "#0099FF",
              color: "#fff",
              borderRadius: "100px",
              width: "283px",
              height: "60px",
              fontWeight: "500",
              fontSize: "20px",
              textTransform: "unset",
            }}
            type="submit"
          >
            Log in
          </Button>
        </form>
      </div>

      <div className="login__right">
        <div className="login__right-box">
          <span className="doira"></span>
          <span className="hira"></span>
        </div>
      </div>
    </div>
  );
};
export default Login;
