import React from "react";
import Login from "../Components/Login";

function LogIn() {
  return (
    <div className="LogIn mx-auto">
      <Login />
    </div>
  );
}

export default LogIn;
